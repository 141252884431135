import FlexFiEditor from "../assets/projects/FlexFiUpscale/Editor.png";
import FlexFiFeatures from "../assets/projects/FlexFiUpscale/Features.png";
import FlexFiMain from "../assets/projects/FlexFiUpscale/Main.png";
import FlexFiPayment from "../assets/projects/FlexFiUpscale/Payment.png";
import FlexFiPricing from "../assets/projects/FlexFiUpscale/Pricing.png";
import FlexFiProfile from "../assets/projects/FlexFiUpscale/Profile.png";

import CSTournaments from "../assets/projects/ESLEsports/CSTournaments.png";
import CSTournaments2 from "../assets/projects/ESLEsports/CSTournaments2.png";
import DotaTournaments from "../assets/projects/ESLEsports/DotaTournaments.png";
import Katowice2024 from "../assets/projects/ESLEsports/Katowice2024.png";
import ESLMain from "../assets/projects/ESLEsports/Main.png";
import ESLMain2 from "../assets/projects/ESLEsports/Main2.png";
import ESLMain3 from "../assets/projects/ESLEsports/Main3.png";
import SignUp from "../assets/projects/ESLEsports/SignUp.png";
import TeamFightTacticsTournaments from "../assets/projects/ESLEsports/TeamFightTacticsTournaments.png";
import TeamFightTacticsTournaments2 from "../assets/projects/ESLEsports/TeamFightTacticsTournaments2.png";

import ReliktArteCheckout from "../assets/projects/ReliktArte/ReliktCheckout.png";
import ReliktArteOrder from "../assets/projects/ReliktArte/ReliktOrder.png";
import ReliktArteProductPage from "../assets/projects/ReliktArte/ReliktProductPage.png";
import ReliktArteProducts from "../assets/projects/ReliktArte/ReliktProducts.png";
import ReliktArteProfile from "../assets/projects/ReliktArte/ReliktProfile.png";
import ReliktArteSignUp from "../assets/projects/ReliktArte/ReliktSignUp.png";

import NeoNestWalletMain from "../assets/projects/NeoNestWallet/NeonestWalletMain.png";
import ReliktArte from "../assets/projects/ReliktArte/ReliktMain.png";

import { DetailedProjectInfoType } from "../pages/DetailedProjectInformation";

export const detailedProjectsData: DetailedProjectInfoType[] = [
    {
        id: 1,
        name: "FlexFi Upscale",
        preview_image: FlexFiMain,
        tags: [1],
        text: `
        FlexFi Upscale is an advanced image enhancement platform designed to meet the needs of both casual users and professionals. It allows you to remove JPEG artifacts, restoring your images to their original clarity, and provides the ability to set custom backgrounds or make them entirely transparent. Additionally, you can upscale your images, enhancing their resolution while maintaining exceptional detail and quality.
\nThe platform offers flexible subscription plans to suit different users.
\nFlexFi Upscale combines powerful tools with simplicity, enabling anyone to elevate their images effortlessly.`,
        photos: [
            FlexFiMain,
            FlexFiFeatures,
            FlexFiEditor,
            FlexFiPricing,
            FlexFiPayment,
            FlexFiProfile,
        ],
    },
    {
        id: 2,
        name: "ESL Esports",
        preview_image: ESLMain,
        tags: [2],
        text: `Dive into the thrilling world of competitive gaming with ESL Esports, the go-to destination for gamers and esports enthusiasts. Connect your Steam account seamlessly to access exclusive features, compete in our action-packed tournaments, and explore a wide range of community-driven events hosted by others.
        \nWhether you’re a casual player or an aspiring pro, ESL Esports offers an immersive experience where you can sharpen your skills, team up with friends, and rise through the ranks. The challenge awaits – are you ready to game?`,
        photos: [
            ESLMain,
            ESLMain2,
            ESLMain3,
            CSTournaments,
            CSTournaments2,
            DotaTournaments,
            TeamFightTacticsTournaments,
            TeamFightTacticsTournaments2,
            Katowice2024,
            SignUp,
        ],
        videos: [],
    },
    {
        id: 3,
        name: "NeoNest Wallet",
        preview_image: NeoNestWalletMain,
        tags: [3],
        text: "NeoNestWallet is a cutting-edge cryptocurrency wallet that allows users to securely store, buy, and track the prices of their chosen cryptocurrencies. Designed for convenience and reliability, it offers nearly real-time price updates and seamless purchasing options, making it an ideal solution for both beginners and experienced crypto enthusiasts.",
        photos: [NeoNestWalletMain],
        videos: [],
    },
    {
        id: 4,
        name: "Relikt Arte",
        preview_image: ReliktArte,
        tags: [1],
        text: "Relikt Arte is a Ukrainian company specializing in the sale of high-quality doors and related accessories, catering to customers across the country. The company focuses on offering a wide range of door styles, from traditional to contemporary, as well as complementary products such as handles, locks, and other hardware. With a commitment to craftsmanship and functionality, Relikt Arte aims to provide durable and stylish solutions for residential and commercial interiors.",
        photos: [
            ReliktArte,
            ReliktArteProducts,
            ReliktArteProductPage,
            ReliktArteSignUp,
            ReliktArteProfile,
            ReliktArteCheckout,
            ReliktArteOrder,
        ],
        videos: [],
    },
];
