import { QuestionType } from "../components/pages/main/FAQS";

export const faqsData: QuestionType[] = [
    {
        question:
            "What types of websites do you specialize in (e.g., e-commerce, portfolio, SaaS platforms)",
        answer: "We specialize in creating all types of websites, tailored to your unique needs. Our mission is to turn your vision into a fully realized, functional, and stunning digital presence.",
    },
    {
        question:
            "Do you provide hosting services, or will I need to arrange my own?",
        answer: "We don’t directly provide hosting services, but we’re happy to assist you in selecting and setting up the right hosting solution during a live consultation. Alternatively, you can handle it independently, and we’ll ensure compatibility with your website.",
    },
    {
        question:
            "How much does a typical project cost, and how is pricing determined?",
        answer: "The cost of a project depends on the technical requirements. We calculate the price based on the estimated development time and the technologies needed. Please note that any features not specified in the technical assignment, even if they seem obvious, will be considered as additional work and charged separately.",
    },
    {
        question:
            "Do you include SEO optimization in your development process?",
        answer: "Currently, we don’t offer SEO optimization as part of our development process, but we focus on building websites that are SEO-friendly to support future optimization efforts.",
    },
];
