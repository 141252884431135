import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SetPreviewSource } from "../../redux/actions";
import "../../styles/components/UI/Collage.scss";
import Loader from "./Loader";

async function getVideoCover(file: string, seekTo = 0.0): Promise<string> {
    return new Promise((resolve, reject) => {
        const videoPlayer = document.createElement("video");
        videoPlayer.setAttribute("src", file);
        videoPlayer.load();

        videoPlayer.addEventListener("error", () => {
            resolve(file);
        });

        videoPlayer.addEventListener("loadedmetadata", () => {
            if (videoPlayer.duration < seekTo) {
                resolve(file);
                return;
            }

            setTimeout(() => {
                videoPlayer.currentTime = seekTo;
            }, 200);

            videoPlayer.addEventListener("seeked", () => {
                const canvas = document.createElement("canvas");
                canvas.width = videoPlayer.videoWidth;
                canvas.height = videoPlayer.videoHeight;

                const ctx = canvas.getContext("2d");
                if (ctx) {
                    ctx.drawImage(
                        videoPlayer,
                        0,
                        0,
                        canvas.width,
                        canvas.height
                    );
                    canvas.toDataURL("image/jpeg", 0.75);
                    resolve(canvas.toDataURL("image/jpeg"));
                } else {
                    resolve(file);
                }
            });
        });
    });
}

const Collage = ({ sources }: { sources: string[] }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [thumbnails, setThumbnails] = useState<any[]>([]);

    const setPreviewSource = (source: any) => {
        dispatch(SetPreviewSource(source));
    };

    useEffect(() => {
        const generateThumbnails = async () => {
            if (!sources || sources.length < 1) return;

            const results = await Promise.all(
                sources.map(async (src) => ({
                    thumbnail: await getVideoCover(src),
                    src,
                }))
            );

            setThumbnails(results);
        };

        generateThumbnails().then(() => {
            setIsLoading(false);
        });
    }, [sources]);

    return isLoading ? (
        <Loader />
    ) : thumbnails.length > 0 ? (
        <div className="collage">
            {thumbnails.map((thumbnail, index) => (
                <div
                    className="thumbnail-container"
                    style={{
                        backgroundImage: `url(${thumbnail.thumbnail})`,
                    }}
                    key={index}
                    onClick={() => {
                        setPreviewSource({
                            ...thumbnail,
                            sources: thumbnails,
                            currentIndex: index,
                        });
                    }}
                ></div>
            ))}
        </div>
    ) : null;
};

export default Collage;
