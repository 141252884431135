export const getBackendUrl = () => {
    let mainPart =
        process.env.REACT_APP_BACKEND_LINK || "http://localhost:8000";

    if (!/^https?:\/\//i.test(mainPart)) {
        mainPart = "http://" + mainPart;
    }

    return mainPart;
};
