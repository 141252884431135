import { SET_WINDOW_WIDTH } from "./types";

const initialState = {
    width: window.innerWidth,
};

export const windowReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_WINDOW_WIDTH:
            return { ...state, width: action.payload };
        default:
            return state;
    }
};
