import { SET_PREVIEW_SOURCE } from "./types";

const initialState = {
    source: "",
};

export const previewReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_PREVIEW_SOURCE:
            return { source: action.payload };

        default:
            return state;
    }
};
