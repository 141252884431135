import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { pageReducer } from "./pageReducer";
import { previewReducer } from "./previewReducer";
import { staticReducer } from "./staticReducer";
import { themeReducer } from "./themeReducer";
import { windowReducer } from "./windowReducer";

const reducer = combineReducers({
    themeReducer,
    pageReducer,
    staticReducer,
    previewReducer,
    windowReducer,
});

export const store = configureStore({
    reducer,
});
