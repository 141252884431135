import { Stack } from "../components/pages/main/OurStackBlock";

import Aiogram from "../assets/technologies/backend/aiogram.png";
import Celery from "../assets/technologies/backend/celery.png";
import Django from "../assets/technologies/backend/django.svg";
import Docker from "../assets/technologies/backend/docker.webp";
import FastAPI from "../assets/technologies/backend/fastapi.svg";
import MongoDB from "../assets/technologies/backend/mongodb.svg";
import NodeJS from "../assets/technologies/backend/nodejs.png";
import Pyramid from "../assets/technologies/backend/pyramid.svg";
import Python from "../assets/technologies/backend/python.webp";
import RabbitMQ from "../assets/technologies/backend/rabbitmq.png";
import Redis from "../assets/technologies/backend/redis.webp";
import SQL from "../assets/technologies/backend/sql.png";
import SQLAlchemy from "../assets/technologies/backend/sqlalchemy.png";
import Websockets from "../assets/technologies/backend/websockets.png";

import Ajax from "../assets/technologies/frontend/ajax.png";
import Angular from "../assets/technologies/frontend/angular.png";
import Bootstrap from "../assets/technologies/frontend/bootstrap.png";
import Figma from "../assets/technologies/frontend/figma.svg";
import JavaScript from "../assets/technologies/frontend/javascript.png";
import JQuery from "../assets/technologies/frontend/jquery.png";
import Less from "../assets/technologies/frontend/less.png";
import React from "../assets/technologies/frontend/react.png";
import RTK from "../assets/technologies/frontend/rtk.png";
import SASSorSCSS from "../assets/technologies/frontend/sass_scss.png";
import Tailwind from "../assets/technologies/frontend/tailwind.png";
import TypeScript from "../assets/technologies/frontend/typescript.png";
import VueJS from "../assets/technologies/frontend/vue.png";

export const stackData: Stack[] = [
    {
        name: "BackEnd",
        technologies: [
            {
                name: "Python",
                image: Python,
            },
            {
                name: "Celery",
                image: Celery,
            },
            {
                name: "Redis",
                image: Redis,
            },
            {
                name: "RabbitMQ",
                image: RabbitMQ,
            },
            {
                name: "Pyramid",
                image: Pyramid,
            },
            {
                name: "Docker",
                image: Docker,
            },
            {
                name: "Node.JS",
                image: NodeJS,
            },
            {
                name: "Django",
                image: Django,
            },
            {
                name: "FastAPI",
                image: FastAPI,
            },
            {
                name: "SQLAlchemy",
                image: SQLAlchemy,
            },
            {
                name: "SQL",
                image: SQL,
            },
            {
                name: "Aiogram",
                image: Aiogram,
            },
            {
                name: "Websockets",
                image: Websockets,
            },
            {
                name: "MongoDB",
                image: MongoDB,
            },
        ],
    },
    {
        name: "FrontEnd",
        technologies: [
            {
                name: "React",
                image: React,
            },
            {
                name: "Vue.JS",
                image: VueJS,
            },
            {
                name: "Angular",
                image: Angular,
            },
            {
                name: "RTK/Query",
                image: RTK,
            },
            {
                name: "TypeScript",
                image: TypeScript,
            },
            {
                name: "JavaScript",
                image: JavaScript,
            },
            {
                name: "SASS/SCSS",
                image: SASSorSCSS,
            },
            {
                name: "Less",
                image: Less,
            },
            {
                name: "Ajax",
                image: Ajax,
            },
            {
                name: "JQuery",
                image: JQuery,
            },
            {
                name: "Bootstrap",
                image: Bootstrap,
            },
            {
                name: "Tailwind CSS",
                image: Tailwind,
            },
            {
                name: "Figma",
                image: Figma,
            },
        ],
    },
];
