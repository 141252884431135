import EslWeb from "../assets/projects/ESLEsports/Icon.png";
import FlexFiUpscale from "../assets/projects/FlexFiUpscale/Icon.png";
import CryptoWallet from "../assets/projects/NeoNestWallet/Icon.png";
import ReliktArte from "../assets/projects/ReliktArte/Icon.png";

import { ProjectType } from "../components/pages/main/PortfolioBlock";

export const projectsData: ProjectType[] = [
    {
        id: 4,
        name: "Relikt Arte",
        tags: [1],
        preview_image: ReliktArte,
        colors: ["#fbfbfb", "#1d1a1a"],
    },
    {
        id: 2,
        name: "ESL Esports",
        tags: [2],
        preview_image: EslWeb,
        colors: ["#C9C814", "#747612"],
    },
    {
        id: 1,
        name: "FlexFi Upscale",
        tags: [1],
        preview_image: FlexFiUpscale,
        colors: ["#645881", "#25203E"],
    },

    {
        id: 3,
        name: "NeoNest Wallet",
        tags: [3],
        preview_image: CryptoWallet,
        colors: ["#42A9E9", "#8A54E6"],
    },
];
