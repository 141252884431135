import { Link, useNavigate } from "react-router-dom";

import CodeSynth from "../assets/CodeSynth.png";
import { paths } from "../router/routes";
import "../styles/components/Footer.scss";

const Footer = () => {
    const navigate = useNavigate();

    const scrollTo = (element_id: string) => {
        const element = document.getElementById(element_id);
        if (element) {
            element.scrollIntoView();
        } else {
            navigate(paths.main + "#" + element_id);
        }
    };

    return (
        <footer id="contacts" className="footer">
            <div className="logo-container">
                <div className="logo">
                    <img alt="CodeSynth" src={CodeSynth} />
                    <Link
                        to="/"
                        className="codesynth-text no-table mid-text"
                        data-text="CODESYNTH"
                    >
                        CodeSynth
                    </Link>
                </div>

                <p className="small-text">
                    We cook powerful websites by ourselves
                </p>
            </div>

            <div className="categories">
                <ul className="category">
                    <p className="mid-text">Navigation</p>
                    <li className="small-text">
                        <p onClick={() => scrollTo("portfolio")}>Portfolio</p>
                    </li>
                    <li className="small-text">
                        <p onClick={() => scrollTo("services")}>Services</p>
                    </li>
                    <li className="small-text">
                        <p onClick={() => scrollTo("why-us")}>Why Us</p>
                    </li>
                    <li className="small-text">
                        <p onClick={() => scrollTo("faqs")}>FAQs</p>
                    </li>
                </ul>

                <ul className="category">
                    <p className="mid-text">Project</p>
                    <li className="small-text">
                        <Link to={paths.makeOrder}>Get started!</Link>
                    </li>
                </ul>

                <ul className="category">
                    <p className="mid-text">Contacts</p>
                    <li className="small-text">
                        <Link
                            to={"mailto:codesynth.pro@gmail.com"}
                            target="_blank"
                        >
                            codesynth.pro@gmail.com
                        </Link>
                    </li>
                    <li className="small-text">
                        <Link to={"https://t.me/las_venturos"} target="_blank">
                            @las_venturos
                        </Link>
                    </li>

                    <li className="small-text">
                        <Link
                            to={"https://t.me/codesynth_manager"}
                            target="_blank"
                        >
                            @codesynth_manager
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
