export const SET_THEME = "SET_THEME";

export const SET_PAGE = "SET_PAGE";

export const SET_TOUCH_DEVICE = "SET_TOUCH_DEVICE";

export const SET_STATIC_DATA = "SET_STATIC_DATA";

export const SET_PREVIEW_SOURCE = "SET_PREVIEW_SOURCE";

export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";

