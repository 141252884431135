import { getBackendUrl } from "./getBackendUrl";

export const getUrl = (url: string): string => {
    const backendUrl = getBackendUrl();

    let mainUrl: string | string[] = backendUrl + url;

    mainUrl = mainUrl.split("://");

    mainUrl[1] = mainUrl[1].replace("//", "/");
    mainUrl = mainUrl.join("://");

    return mainUrl as string;
};
