import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SetPreviewSource } from "../../redux/actions";
import "../../styles/components/UI/Preview.scss";

const Preview = () => {
    const dispatch = useDispatch();
    const video = ["mp4", "mov"];
    const currentSource = useSelector(
        (state: any) => state.previewReducer.source
    );

    const touchDevice = useSelector(
        (state: any) => state.pageReducer.touchDevice
    );

    const getSourceExtension = () => {
        const splittedSource = currentSource.src.split(".");
        const sourceExtension = splittedSource[splittedSource.length - 1];

        return sourceExtension;
    };

    const setCurrentSource = (newIndex: number) => {
        const newSource =
            newIndex >= 0
                ? {
                      ...currentSource.sources[newIndex],
                      sources: currentSource.sources,
                      currentIndex: newIndex,
                  }
                : "";

        console.log(newSource);

        dispatch(SetPreviewSource(newSource));
    };

    useEffect(() => {
        document.documentElement.style.overflowY = currentSource
            ? "hidden"
            : "auto";

        if (currentSource && currentSource.sources.length > 1) {
            window.onkeydown = (e: any) => {
                if (e.keyCode === 39) {
                    changeSource(1);
                } else if (e.keyCode === 37) {
                    changeSource(-1);
                }
            };
        } else {
            window.onkeydown = () => null;
        }

        const navbar = document.getElementById("navbar");

        if (touchDevice) {
            const mobileButton = document.querySelector(
                ".navbar-mobile-button"
            );

            if (currentSource) {
                mobileButton?.classList.remove("active");
                navbar?.classList.remove("active");
            } else {
                mobileButton?.classList.add("active");
                navbar?.classList.add("active");
            }
        } else {
            if (currentSource) {
                navbar?.style.setProperty("top", "-25%");
            } else {
                navbar?.style.setProperty("top", "0%");
            }
        }
    }, [currentSource]);

    const changeSource = (action: number) => {
        const sourceNewIndex =
            action < 0
                ? currentSource.currentIndex > 0
                    ? currentSource.currentIndex - 1
                    : currentSource.sources.length - 1
                : action > 0 &&
                  currentSource.currentIndex < currentSource.sources.length - 1
                ? currentSource.currentIndex + 1
                : 0;

        setCurrentSource(sourceNewIndex);
    };

    return (
        currentSource && (
            <div
                className="preview-container"
                onClick={() => setCurrentSource(-1)}
            >
                {currentSource.sources.length > 1 && (
                    <>
                        <div
                            className="arrow left"
                            onClick={(e) => {
                                e.stopPropagation();

                                changeSource(-1);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlSpace="preserve"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8    S16.4,20,12,20z"
                                />

                                <path
                                    fill="currentColor"
                                    d="M13.7,8.3c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4l3,3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3    c0.4-0.4,0.4-1,0-1.4L11.4,12l2.3-2.3C14.1,9.3,14.1,8.7,13.7,8.3z"
                                />
                            </svg>
                        </div>
                        <div
                            className="arrow right"
                            onClick={(e) => {
                                e.stopPropagation();

                                changeSource(1);
                            }}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 24 24"
                                version="1.1"
                                xmlSpace="preserve"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M12,20c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8    S16.4,20,12,20z"
                                />

                                <path
                                    fill="currentColor"
                                    d="M13.7,8.3c-0.4-0.4-1-0.4-1.4,0l-3,3c-0.4,0.4-0.4,1,0,1.4l3,3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3    c0.4-0.4,0.4-1,0-1.4L11.4,12l2.3-2.3C14.1,9.3,14.1,8.7,13.7,8.3z"
                                />
                            </svg>
                        </div>
                    </>
                )}

                <div className="source-count">
                    {currentSource.currentIndex + 1}/
                    {currentSource.sources.length}
                </div>

                <div
                    className="source-container"
                    onClick={(e) => e.stopPropagation()}
                >
                    {video.some(
                        (extension) => getSourceExtension() === extension
                    ) ? (
                        <video
                            className="source"
                            src={currentSource.src}
                            controls
                        />
                    ) : (
                        <img className="source" src={currentSource.src} />
                    )}
                </div>
            </div>
        )
    );
};

export default Preview;
